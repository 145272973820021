<template>
	<header v-if="headingImage" ref="header" :class="{ subpage: !route.name.startsWith('index') }">
		<div v-if="vimeoid">
			<div class="hero-image video-header">
				<slot name="heroContent" />
				<div style="padding: 56.25% 0 0; position: relative; background-size: cover">
					<iframe
						:src="
							'https://player.vimeo.com/video/' +
							vimeoid +
							'&amp;badge=0&amp;autopause=0&amp;loop=0&amp;muted=1&amp;controls=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479'
						"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
						style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2"
						title="Witte Berken - Natuurhotel & Restaurant"
					/>
				</div>
				<a v-if="route.name.startsWith('index')" class="scroll-down smooth-scroll" @click="scrollDown()" />
				<div
					style="
						position: absolute;
						inset: 0;
						z-index: 1;
						background-size: cover;
						background-position: center center;
					"
					:style="{ 'background-image': 'url(' + vimeoImage + ')' }"
				/>
			</div>
		</div>
		<div v-else>
			<div class="hero-image image-header">
				<slot name="heroContent" />
				<picture>
					<source
						v-if="headingImage.imagePortrait"
						:srcset="headingImage.imagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="headingImage.imageWebp" type="image/webp" />
					<source :srcset="headingImage.image" />
					<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
				</picture>
				<a v-if="route.name.startsWith('index')" class="scroll-down smooth-scroll" @click="scrollDown()" />
			</div>
		</div>
	</header>
</template>

<script setup>
const route = useRoute();

const props = defineProps({
	headingImage: { type: Object, default: () => {} },
	vimeoid: { type: String, default: '' },
	vimeoImage: { type: String, default: '' },
	slug: { type: String, default: '' },
});

const header = ref(null);
const scrollDown = () => {
	const scrollHeight = header?.value.clientHeight;

	if (!scrollHeight) {
		return;
	}
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};

if (props.vimeoid) {
	useHead({
		script: [
			{
				hid: 'vimeo-player',
				src: 'https://player.vimeo.com/api/player.js',
			},
		],
	});
}
</script>

<style lang="scss" scoped>
.hero-image {
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;

	&.video-header {
		z-index: 3;
		max-height: 100vh;
		height: auto;
	}

	.hero-title {
		display: block;
	}
}

.hero-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.subpage .hero-image {
	height: 50vh;

	.image-header {
		display: block;
	}
}

.hero-image::before {
	content: '';
	z-index: 2;
	position: absolute;
	display: block;
	inset: 0;
	background: rgba(0 0 0 / 20%);
}

.news-item .hero-image::before {
	display: none;
}

.scroll-down {
	position: absolute;
	cursor: pointer;
	bottom: 40px;
	left: 0;
	right: 0;
	margin: 0 auto;
	background: url('~/assets/images/scroll-down-custom.png') no-repeat center center;
	width: 60px;
	height: 70px;
	background-size: 59px;
	z-index: 2;
}

@media (max-width: 880px) {
	.hero-content .hero-title {
		font-size: 62px;
	}

	.hero-content .hero-subtitle {
		font-size: 24px;
	}

	/* Video header */
	.video-header {
		min-height: 640px;

		iframe {
			width: 360% !important;
			height: 130% !important;
			margin-left: -130% !important;
			min-height: 640px !important;

			&.subpage-iframe {
				width: 100% !important;
				height: 100% !important;
				margin-left: 0 !important;
				min-height: 100px !important;
			}
		}
	}

	.image-header {
		picture,
		div {
			display: block;
		}
	}

	.hero-image {
		img {
			position: absolute;
		}
	}
}

@media (max-width: 680px) {
	.hero-content .hero-title {
		font-size: 54px;
	}

	.hero-content .hero-subtitle {
		font-size: 22px;
	}
}

@media (max-width: 520px) {
	.hero-content .hero-title {
		font-size: 46px;
	}

	.hero-content .hero-subtitle {
		font-size: 20px;
	}
}
</style>
